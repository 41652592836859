import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../http/api';
import { Endpoints } from '../../../http/Endpoints';
import { MESSAGE } from '../../../global-constants';

export const SettingsAll = createAsyncThunk('settings', async (params, { rejectWithValue }) => {
    try {
        const { surveyId = '' } = params;
        const response = await api.get(Endpoints.getSurveyOverview.replace(':survey_id', surveyId));
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const GetSurveyConfig = createAsyncThunk('getSettingsValue', async (params, { rejectWithValue }) => {
    try {
        const { surveyId = '' } = params;
        const response = await api.get(Endpoints.surveyConfig.replace(':survey_id', surveyId));
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const saveAllSettings = createAsyncThunk('saveSettings', async (params, { rejectWithValue }) => {
    try {
        const {
            enable_progress_bar,
            enable_language_selection,
            enable_font_increase,
            enable_glossary,
            layout,
            allowed_layout,
            surveyId,
            shouldHideModal,
            enable_dark_mode,
        } = params;
        const payload = {
            enable_progress_bar: enable_progress_bar.toString(),
            enable_language_selection: enable_language_selection.toString(),
            enable_font_increase: enable_font_increase.toString(),
            enable_glossary: enable_glossary.toString(),
            enable_dark_mode: enable_dark_mode.toString(),
            layout,
            allowed_layout,
        };
        const response = await api.put(Endpoints.surveyConfig.replace(':survey_id', surveyId), payload);
        const { data } = response;
        if (shouldHideModal) {
            data.shouldHideModal = true;
        }
        return data;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const saveShowLogo = createAsyncThunk('updateShowLogo', async params => {
    try {
        const response = await api.put(Endpoints.surveyConfig.replace(':survey_id', params?.surveyId), {
            show_logo: params?.show_logo?.toString(),
        });
        return response?.data;
    } catch (error) {
        return;
    }
});
