import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../http/api';
import { Endpoints } from '../../../http/Endpoints';
import { MESSAGE } from '../../../global-constants';
import { updateStandard } from '../../reducers/slice/Demographics';

export const GetStandardDemoQues = createAsyncThunk('demostandard', async (params, { rejectWithValue, dispatch }) => {
    try {
        const { surveyId, tabClick = false, is_custom } = params;
        const url = Endpoints.getStandardDemo
            .concat('?question_type=DEMOGRAPHIC&is_uploaded=false')
            .concat(`&is_custom=${is_custom}`);
        const response = await api.get(url.replace(':survey_id', surveyId));
        if (tabClick) {
            dispatch(GetDemographicQues({ surveyId, is_custom: true }));
        }
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const GetDemographicQues = createAsyncThunk('demo', async (params, { rejectWithValue }) => {
    try {
        const url = Endpoints.getStandardDemo
            .concat('?question_type=DEMOGRAPHIC&is_uploaded=false')
            .concat(`&is_custom=${params.is_custom}`);
        const response = await api.get(url.replace(':survey_id', params.surveyId));
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const SaveStandardDemo = createAsyncThunk('saveStandard', async (params, { rejectWithValue, dispatch }) => {
    try {
        const { surveyId, questionId, use_in_survey, demo_visible, isCustom } = params;
        const url = Endpoints.saveStandardDemo.replace(':survey_id', surveyId).replace(':question_id', questionId);
        let payload = {};
        if (typeof use_in_survey == 'boolean') payload.use_in_survey = use_in_survey;
        if (demo_visible) payload = demo_visible;
        const response = await api.patch(url, payload);
        if (!isCustom) {
            dispatch(updateStandard({ questionId, use_in_survey, demo_visible }));
        }
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const updateStandardCustomDemo = createAsyncThunk(
    'updateStandardDemoQues',
    async ({ surveyId, questionId, params }, { rejectWithValue }) => {
        try {
            const url = Endpoints.ohiTemplateQuestionPut
                .replace(':survey_id', surveyId)
                .replace(':question_id', questionId);
            const response = await api.put(url, {
                ...params,
            });
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const updateInstructionDemo = createAsyncThunk(
    'updateinstructionDemo',
    async ({ surveyid, groupId, requestParams }, { rejectWithValue }) => {
        try {
            const url = Endpoints.updateInstructions
                .replace(':survey_id', surveyid)
                .replace(':ques_sub_group_id', groupId);
            const response = await api.put(url, { ...requestParams });
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);
