import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Typography,
    FormControlLabel,
} from '@mui/material';
import React, { useState } from 'react';
import {
    CTA,
    FlexCenterWithAlign,
    FlexColumn,
    FlexColumnCenter,
    FlexColumnGap,
    FlexRow,
    FlexStartGap,
} from './styled/styled';
import CloseIcon from '../assets/img/close.svg';
import { colorsCode, pxToVh, pxToVw } from './colors';
import styled from 'styled-components';
import { CommonLabel, CommonChip } from './FormInputs/Common';
import WarningIcon from '@mui/icons-material/Warning';
import { getSentenceCase } from '../utils/functions';
import './index.scss';
import { ModalSuccess } from '../assets/img/svgs';
import comingSoonImg from '../assets/img/comingSoon.svg';
import noteIcon from '../assets/img/note.svg';
import { SURVEY_STATUS, SURVEY_TYPE_MAPPING } from '../global-constants';

export const DialogModal = ({ open, close }) => {
    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVw(700)}vw`,
                    width: `${pxToVw(700)}vw`,
                },
            }}
        >
            <DialogTitle
                sx={{
                    paddingTop: `${pxToVh(40)}vh`,
                }}
            >
                <FlexCenterWithAlign>
                    <CommonLabel
                        label="Demo video coming soon!"
                        textColor={colorsCode.TextHeading}
                        textWeight={500}
                        textSize={24}
                        lineHeight={36}
                    />
                </FlexCenterWithAlign>
            </DialogTitle>
            <DialogContent
                sx={{
                    padding: `${pxToVh(40)}vh ${pxToVh(20)}vw`,
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <img src={CloseIcon} alt="dialog" />
                </IconButton>
                <FlexColumnCenter>
                    <img src={comingSoonImg} style={{ height: '28vh', marginTop: '1.2rem' }} alt="loading" />
                    <DialogContentText>
                        <CommonLabel
                            label={
                                'Stay tuned for a demo video showcasing our product.  In the meantime, feel free to explore the other resources available on the Knowledge Centre.'
                            }
                            lineHeight={24}
                            textSize={16}
                            textWeight={400}
                            textColor={colorsCode.TextHeading}
                            textAlign={'center'}
                            customStyles={{ marginTop: '1.2rem' }}
                        />
                    </DialogContentText>
                </FlexColumnCenter>
            </DialogContent>
        </Dialog>
    );
};

export const SuccessModal = ({
    title,
    message,
    onClose,
    open,
    confirmFlag = false,
    type = '',
    btnYes = '',
    btnCancel,
    onYes = () => {},
}) => {
    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVw(580)}vw`,
                    width: `${pxToVw(480)}vw`,
                },
            }}
        >
            <DialogContent
                sx={{
                    padding: `${pxToVh(20)}vh ${pxToVh(20)}vw`,
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <img src={CloseIcon} alt="" />
                </IconButton>
                <DialogContentText>
                    <br />
                    <FlexColumnGap gap={pxToVh(40)} style={{ alignItems: 'center' }}>
                        <FlexColumnGap gap={pxToVh(16)} className="dialogContent" style={{ alignItems: 'center' }}>
                            <FlexColumnGap gap={pxToVh(4)} className="dialogContent" style={{ alignItems: 'center' }}>
                                {confirmFlag || type === 'error' ? (
                                    <WarningIcon
                                        sx={{
                                            color: type === 'error' ? colorsCode.error : colorsCode.warning,
                                            fontSize: `${pxToVh(48)}vh`,
                                        }}
                                    />
                                ) : (
                                    <ModalSuccess />
                                )}
                                <CommonLabel
                                    label={title}
                                    lineHeight={36}
                                    textSize={24}
                                    textWeight={500}
                                    textColor={colorsCode.TextHeading}
                                    customStyles={{
                                        textAlign: 'center',
                                    }}
                                />
                            </FlexColumnGap>
                            <CommonLabel
                                label={`${message}`}
                                lineHeight={20}
                                textSize={16}
                                textWeight={400}
                                textColor={colorsCode.TextHeading}
                                customStyles={{
                                    textAlign: 'center',
                                }}
                            />
                        </FlexColumnGap>
                    </FlexColumnGap>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', mt: 2, mb: 4 }}>
                <FlexStartGap gap={pxToVw(24)}>
                    <React.Fragment>
                        <CTA
                            pl={16}
                            pt={8}
                            fw={400}
                            lh={24}
                            size={16}
                            color={colorsCode.PrimaryDeepBlue}
                            bcolor={colorsCode.PrimaryDeepBlue}
                            bg={colorsCode.white}
                            onClick={onClose}
                        >
                            {type ? 'Okay' : btnCancel}
                        </CTA>
                        {confirmFlag ? (
                            <CTA
                                pl={16}
                                pt={8}
                                fw={400}
                                lh={24}
                                size={16}
                                color={colorsCode.white}
                                bg={colorsCode.PrimaryDeepBlue}
                                onClick={onYes}
                            >
                                {btnYes}
                            </CTA>
                        ) : null}
                    </React.Fragment>
                </FlexStartGap>
            </DialogActions>
        </Dialog>
    );
};

export const ViewAllModal = ({ title, description, list, open, close }) => {
    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: {
                    minWidth: `${pxToVw(570)}vw`,
                    maxWidth: `${pxToVw(640)}vw`,
                    width: 'auto',
                    margin: 'auto',
                    maxHeight: `${pxToVh(720)}vh`,
                    padding: `${pxToVh(40)}vh ${pxToVw(40)}vw`,
                },
            }}
        >
            <IconButton
                aria-label="close"
                onClick={close}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <img src={CloseIcon} alt="" />
            </IconButton>
            <Typography
                fontWeight={500}
                sx={{
                    fontSize: `${pxToVh(24)}vh`,
                    lineHeight: `${pxToVh(36)}vh`,
                    color: `${colorsCode.TextHeading}`,
                    padding: `0 0 ${pxToVh(8)}vh 0`,
                }}
            >
                {title}
            </Typography>
            <Typography
                sx={{
                    fontSize: `${pxToVh(14)}vh`,
                    fontWeight: 400,
                    lineHeight: `${pxToVh(20)}vh`,
                    color: `${colorsCode.Neutral80}`,
                }}
            >
                {description}
            </Typography>
            <OptionsDiv>
                <FlexRow>
                    <div style={{ flex: 1.2 }}>
                        <CommonLabel
                            label={'Options'}
                            textWeight={300}
                            textSize={12}
                            textColor={colorsCode.TextSubtle}
                        />
                    </div>
                    <div style={{ flex: 0.5 }}>
                        <CommonLabel
                            label="Best"
                            textWeight={300}
                            textSize={12}
                            textColor={colorsCode.TextSubtle}
                            textAlign="center"
                        />
                    </div>
                    <div style={{ flex: 0.3 }}>
                        <CommonLabel
                            label="Least"
                            textWeight={300}
                            textSize={12}
                            textColor={colorsCode.TextSubtle}
                            textAlign="center"
                        />
                    </div>
                </FlexRow>
                {list?.map((item, index) => (
                    <FlexRow key={index}>
                        <Typography sx={{ flex: 0.7 }}>{item.question_text}</Typography>
                        <Checkbox sx={{ flex: 0.3 }} disabled={true} checked={index < 7}></Checkbox>
                        <Checkbox sx={{ flex: 0.3 }} disabled={true} checked={index >= 7 && index < 12}></Checkbox>
                    </FlexRow>
                ))}
            </OptionsDiv>
        </Dialog>
    );
};

export const PreviewModal = ({ title, description, list, open, close }) => {
    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: {
                    minWidth: `${pxToVw(570)}vw`,
                    maxWidth: `${pxToVw(640)}vw`,
                    width: 'auto',
                    margin: 'auto',
                    maxHeight: `${pxToVh(720)}vh`,
                    padding: `${pxToVh(40)}vh ${pxToVw(40)}vw`,
                },
            }}
        >
            <IconButton
                aria-label="close"
                onClick={close}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <img src={CloseIcon} alt="" />
            </IconButton>
            <Typography
                fontWeight={500}
                sx={{ fontSize: `${pxToVh(24)}vh`, lineHeight: `${pxToVh(36)}vh`, color: colorsCode.TextHeading }}
            >
                {title}
            </Typography>
            <Typography
                sx={{
                    fontSize: `${pxToVh(14)}vh`,
                    fontWeight: 400,
                    lineHeight: `${pxToVh(20)}vh`,
                    color: `${colorsCode.Neutral80}`,
                    margin: `${pxToVh(8)}vh 0 ${pxToVh(36)}vh 0`,
                }}
            >
                {description}
            </Typography>
            <PreviewDiv gap={pxToVh(20)}>
                {list.map((item, index) => (
                    <PreviewItem gap={pxToVh(20)} key={index}>
                        <Typography
                            sx={{
                                fontSize: `${pxToVh(18)}vh`,
                                lineHeight: `${pxToVh(28)}vh`,
                                fontWeight: '400',
                                color: colorsCode.Neutral80,
                            }}
                        >
                            {item.quesTitle}
                        </Typography>
                        <FlexRow style={{ width: '100%' }} gap={pxToVw(8)}>
                            {item.quesOptions.map((item, index) => (
                                <QuestOptionText key={index}>{item.option}</QuestOptionText>
                            ))}
                        </FlexRow>
                    </PreviewItem>
                ))}
            </PreviewDiv>
        </Dialog>
    );
};

export const SurveyLimitDialog = ({ close, open, heading = '', hideDescription = false }) => {
    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVh(480)}vh`,
                },
            }}
        >
            <DialogContent
                sx={{
                    padding: !hideDescription ? `${pxToVh(20)}vh ${pxToVh(20)}vw` : `${pxToVh(20)}vh ${pxToVh(20)}vw 0`,
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={() => close(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <img src={CloseIcon} alt="" />
                </IconButton>
                <DialogContentText>
                    <br />
                    <FlexColumnGap gap={pxToVh(16)} style={{ alignItems: 'center' }}>
                        <FlexColumnGap gap={pxToVh(2)} className="dialogContent" style={{ alignItems: 'center' }}>
                            <WarningIcon sx={{ color: colorsCode.error, fontSize: `${pxToVh(48)}vh` }} />
                            <CommonLabel
                                label={heading || 'You have reached the maximum limit of available surveys'}
                                lineHeight={36}
                                textSize={24}
                                textWeight={500}
                                textColor={colorsCode.TextHeading}
                                customStyles={{
                                    textAlign: 'center',
                                }}
                            />
                        </FlexColumnGap>
                        {!hideDescription ? (
                            <FlexColumnGap gap={pxToVh(2)}>
                                <CommonLabel
                                    label="Contact Admin to increase available limit"
                                    lineHeight={24}
                                    textSize={16}
                                    textWeight={400}
                                    textColor={colorsCode.TextHeading}
                                />
                            </FlexColumnGap>
                        ) : null}
                    </FlexColumnGap>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', marginBottom: '2vh', marginTop: '3vh' }}>
                <CTA
                    pl={16}
                    pt={8}
                    lh={24}
                    size={16}
                    bg={colorsCode.PrimaryDeepBlue}
                    color={colorsCode.white}
                    onClick={() => close(false)}
                >
                    Close
                </CTA>
            </DialogActions>
        </Dialog>
    );
};

export const DeleteDialog = ({
    close,
    open,
    heading = '',
    description = '',
    confirmationText = '',
    cancelText,
    confirmText,
    onConfirm,
}) => {
    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVw(580)}vw`,
                },
            }}
        >
            <DialogContent
                sx={{
                    padding: `${pxToVh(56)}vh ${pxToVh(40)}vw 0`,
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={() => close(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <img src={CloseIcon} alt="" />
                </IconButton>
                <DialogContentText>
                    <FlexColumnGap gap={pxToVh(16)} style={{ alignItems: 'center' }}>
                        <FlexColumnGap gap={pxToVh(4)} className="dialogContent" style={{ alignItems: 'center' }}>
                            <WarningIcon sx={{ color: colorsCode.warning, fontSize: `${pxToVh(48)}vh` }} />
                            <CommonLabel
                                label={heading}
                                lineHeight={36}
                                textSize={24}
                                textWeight={500}
                                textColor={colorsCode.TextHeading}
                                customStyles={{
                                    textAlign: 'center',
                                }}
                            />
                        </FlexColumnGap>
                        <FlexColumnGap gap={pxToVh(4)} style={{ alignItems: 'center' }}>
                            {description ? (
                                <CommonLabel
                                    label={description}
                                    lineHeight={24}
                                    textSize={16}
                                    textWeight={400}
                                    textColor={colorsCode.TextHeading}
                                    customStyles={{
                                        textAlign: 'center',
                                    }}
                                />
                            ) : null}
                            {confirmationText ? (
                                <CommonLabel
                                    label={confirmationText}
                                    lineHeight={24}
                                    textSize={16}
                                    textWeight={400}
                                    textColor={colorsCode.TextHeading}
                                    customStyles={{
                                        textAlign: 'center',
                                    }}
                                />
                            ) : null}
                        </FlexColumnGap>
                    </FlexColumnGap>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', margin: `${pxToVh(40)}vh 0`, padding: '0' }}>
                <FlexStartGap gap={pxToVw(24)}>
                    <React.Fragment>
                        <CTA
                            pl={16}
                            pt={8}
                            lh={24}
                            size={16}
                            bg={colorsCode.white}
                            color={colorsCode.PrimaryDeepBlue}
                            bcolor={colorsCode.PrimaryDeepBlue}
                            onClick={() => close(false)}
                        >
                            {cancelText || 'Cancel'}
                        </CTA>
                        <CTA
                            pl={16}
                            pt={8}
                            lh={24}
                            size={16}
                            bg={colorsCode.PrimaryDeepBlue}
                            bcolor={colorsCode.PrimaryDeepBlue}
                            color={colorsCode.white}
                            onClick={onConfirm}
                        >
                            {confirmText || 'Delete'}
                        </CTA>
                    </React.Fragment>
                </FlexStartGap>
            </DialogActions>
        </Dialog>
    );
};

export const LanguageRemoveWarningDialog = ({
    close,
    open,
    heading = '',
    description = '',
    confirmationText = '',
    cancelText,
    confirmText,
    onConfirm,
}) => {
    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVw(580)}vw`,
                },
            }}
        >
            <DialogContent
                sx={{
                    padding: `${pxToVh(56)}vh ${pxToVh(40)}vw 0`,
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={() => close(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <img src={CloseIcon} alt="" />
                </IconButton>
                <DialogContentText>
                    <FlexColumnGap gap={pxToVh(16)} style={{ alignItems: 'center' }}>
                        <FlexColumnGap gap={pxToVh(4)} className="dialogContent" style={{ alignItems: 'center' }}>
                            <WarningIcon sx={{ color: colorsCode.error, fontSize: `${pxToVh(48)}vh` }} />
                            <CommonLabel
                                label={heading}
                                lineHeight={36}
                                textSize={24}
                                textWeight={500}
                                textColor={colorsCode.TextHeading}
                                customStyles={{
                                    textAlign: 'center',
                                }}
                            />
                        </FlexColumnGap>
                        <FlexColumnGap gap={pxToVh(4)} style={{ alignItems: 'center' }}>
                            {description ? (
                                <CommonLabel
                                    label={description}
                                    lineHeight={24}
                                    textSize={16}
                                    textWeight={400}
                                    textColor={colorsCode.TextHeading}
                                    customStyles={{
                                        textAlign: 'center',
                                    }}
                                />
                            ) : null}
                            {confirmationText ? (
                                <CommonLabel
                                    label={confirmationText}
                                    lineHeight={24}
                                    textSize={16}
                                    textWeight={400}
                                    textColor={colorsCode.TextHeading}
                                    customStyles={{
                                        textAlign: 'center',
                                    }}
                                />
                            ) : null}
                        </FlexColumnGap>
                    </FlexColumnGap>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', margin: `${pxToVh(40)}vh 0`, padding: '0' }}>
                <FlexStartGap gap={pxToVw(24)}>
                    <React.Fragment>
                        <CTA
                            pl={16}
                            pt={8}
                            lh={24}
                            size={16}
                            bg={colorsCode.white}
                            color={colorsCode.PrimaryDeepBlue}
                            bcolor={colorsCode.PrimaryDeepBlue}
                            onClick={() => close(false)}
                        >
                            {cancelText}
                        </CTA>
                        <CTA
                            pl={16}
                            pt={8}
                            lh={24}
                            size={16}
                            bg={colorsCode.PrimaryDeepBlue}
                            bcolor={colorsCode.PrimaryDeepBlue}
                            color={colorsCode.white}
                            onClick={onConfirm}
                        >
                            {confirmText}
                        </CTA>
                    </React.Fragment>
                </FlexStartGap>
            </DialogActions>
        </Dialog>
    );
};

export const SurveyCloseDialog = ({
    onClose,
    onActionPressed,
    open,
    isClose = SURVEY_STATUS.COMPLETED,
    maintenanceMode = false,
}) => {
    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVh(580)}vh`,
                },
            }}
        >
            <DialogContent
                sx={{
                    padding: `${pxToVh(20)}vh ${pxToVh(20)}vw`,
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <img src={CloseIcon} alt="" />
                </IconButton>
                <DialogContentText>
                    <br />
                    <FlexColumnGap gap={pxToVh(16)}>
                        <FlexRow gap={pxToVw(12)} className="dialogContent" style={{ alignItems: 'center' }}>
                            <WarningIcon
                                sx={{
                                    color: !isClose ? colorsCode.warning : colorsCode.error,
                                    fontSize: `${pxToVh(24)}vh`,
                                }}
                            />
                            <CommonLabel
                                label={
                                    isClose
                                        ? 'You are about to close the survey!'
                                        : maintenanceMode
                                        ? 'You are about to enable maintenance mode'
                                        : 'You are about to disable maintenance mode'
                                }
                                lineHeight={36}
                                textSize={24}
                                textWeight={500}
                                textColor={colorsCode.TextHeading}
                                customStyles={{
                                    textAlign: 'center',
                                }}
                            />
                        </FlexRow>
                        <FlexColumnGap gap={pxToVh(4)}>
                            {isClose ? (
                                <StyledListContainer>
                                    <StyledList>
                                        Please note once you close the survey, you wouldn&apos;t be able to record the
                                        responses of any participant post closure.
                                    </StyledList>
                                    <StyledList>
                                        Results would be generated post review by the McKinsey Coach.
                                    </StyledList>
                                </StyledListContainer>
                            ) : (
                                <StyledListContainer>
                                    {maintenanceMode ? (
                                        <StyledList>
                                            Please note once you enable maintenance mode, user wont be able to access
                                            the survey link.
                                        </StyledList>
                                    ) : (
                                        <StyledList>
                                            Please note once you disable maintenance mode, user will be able to access
                                            the survey link
                                        </StyledList>
                                    )}
                                </StyledListContainer>
                            )}
                            <CommonLabel
                                label="Are you sure you want to proceed?"
                                lineHeight={24}
                                textSize={16}
                                textWeight={400}
                                textColor={colorsCode.TextHeading}
                            />
                        </FlexColumnGap>
                    </FlexColumnGap>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', gap: '2vw', marginBottom: '2vh', marginTop: '3vh' }}>
                <CTA
                    pl={16}
                    pt={8}
                    lh={24}
                    size={16}
                    bg={colorsCode.white}
                    color={colorsCode.PrimaryDeepBlue}
                    bcolor={colorsCode.PrimaryDeepBlue}
                    onClick={onClose}
                >
                    Cancel
                </CTA>
                <CTA
                    pl={16}
                    pt={8}
                    lh={24}
                    size={16}
                    bg={colorsCode.PrimaryDeepBlue}
                    color={colorsCode.white}
                    onClick={onActionPressed}
                >
                    {isClose
                        ? 'Close Survey'
                        : maintenanceMode
                        ? 'Enable maintenance mode'
                        : 'Disable maintenance mode'}
                </CTA>
            </DialogActions>
        </Dialog>
    );
};

export const SettingsPreviewModal = ({ title, description, image, open, close, showWarning }) => {
    return (
        <Dialog
            disableEscapeKeyDown
            open={open}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVw(760)}vw`,
                },
            }}
        >
            <DialogContent
                sx={{
                    padding: `${pxToVh(40)}vh ${pxToVh(40)}vw`,
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <img src={CloseIcon} alt="" />
                </IconButton>
                <FlexColumnGap gap={pxToVh(28)}>
                    <FlexColumnGap gap={pxToVh(16)} className="dialogContent" style={{ width: '100%' }}>
                        <CommonLabel
                            label={getSentenceCase(`${title} layout`)}
                            lineHeight={26}
                            textSize={24}
                            textWeight={500}
                            textColor={colorsCode.TextHeading}
                        />
                        <CommonLabel
                            label={description.replace(/. Not ideal for long surveys/gi, '')}
                            lineHeight={24}
                            textSize={16}
                            textWeight={400}
                            textColor={colorsCode.TextHeading}
                        />
                    </FlexColumnGap>
                    <PreviewImage src={image} alt={`${title}-img`} />
                    {showWarning ? (
                        <WarningContainer gap={pxToVw(8)} style={{ justifyContent: 'center' }}>
                            <WarningIcon
                                sx={{
                                    color: colorsCode.warning,
                                    width: `${pxToVw(16)}vw`,
                                    height: `${pxToVh(16)}vh`,
                                }}
                            />
                            <CommonLabel
                                textColor={colorsCode.TextSubtle}
                                textSize={12}
                                lineHeight={16}
                                textWeight={400}
                                label="Not ideal for more than 30 questions"
                            />
                        </WarningContainer>
                    ) : null}
                </FlexColumnGap>
            </DialogContent>
        </Dialog>
    );
};

const LabelValueWrapper = styled(FlexColumn)`
 & > div:nth-child(2) {
   margin-top: 0.5rem;
 },
`;

export const EditModal = ({ open, close, surveydata, handleOnSave, changingStatusTo }) => {
    const [checkboxSelected, setCheckboxSelected] = useState(false);
    const { is_combined_survey } = surveydata;

    const changeDisclaimer = e => {
        setCheckboxSelected(e.target.checked);
    };

    return (
        <Dialog
            disableEscapeKeyDown
            open={open}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVw(580)}vw`,
                },
            }}
        >
            <DialogContent
                sx={{
                    padding: `${pxToVh(30)}vh ${pxToVh(25)}vw`,
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <img src={CloseIcon} alt="" />
                </IconButton>
                <FlexColumnGap gap={pxToVh(20)}>
                    <FlexColumnGap gap={pxToVh(4)} className="dialogContent" style={{ width: '100%' }}>
                        <CommonLabel
                            label={getSentenceCase(`Change survey status`)}
                            lineHeight={26}
                            textSize={24}
                            textWeight={500}
                            textColor={colorsCode.TextHeading}
                        />
                    </FlexColumnGap>
                    <NoteContainer gap={pxToVw(8)} style={{ justifyContent: 'center', alignItems: 'start' }}>
                        <img src={noteIcon} style={{ marginTop: `${pxToVh(5)}vh` }} alt="loading" />
                        <NotesSection>
                            <span>
                                <b style={{ fontWeight: 500 }}>Note:</b> Modifying your survey status is a significant
                                action that will impact the overall status of your survey. Please ensure you have
                                sufficient reason and approval to perform the action.
                            </span>
                        </NotesSection>
                    </NoteContainer>
                    <FlexRow style={{ width: '100%' }}>
                        <LabelValueWrapper style={{ flex: 0.5 }}>
                            <CommonLabel
                                label={'Survey ID'}
                                lineHeight={18}
                                textSize={16}
                                textWeight={400}
                                textColor={colorsCode.Neutral30}
                            />
                            <CommonLabel
                                label={surveydata.survey_id}
                                lineHeight={18}
                                textSize={16}
                                textWeight={400}
                                textColor={colorsCode.Neutral80}
                            />
                        </LabelValueWrapper>
                        <LabelValueWrapper style={{ flex: 0.5 }}>
                            <CommonLabel
                                label={'Survey name'}
                                lineHeight={18}
                                textSize={16}
                                textWeight={400}
                                textColor={colorsCode.Neutral30}
                            />
                            <CommonLabel
                                label={surveydata.survey_name}
                                lineHeight={18}
                                textSize={16}
                                textWeight={400}
                                textColor={colorsCode.Neutral80}
                            />
                        </LabelValueWrapper>
                    </FlexRow>
                    <FlexRow style={{ width: '100%' }}>
                        <LabelValueWrapper style={{ flex: 0.5 }}>
                            <CommonLabel
                                label={'Survey Link Type'}
                                lineHeight={18}
                                textSize={16}
                                textWeight={400}
                                textColor={colorsCode.Neutral30}
                            />
                            <CommonChip
                                value={
                                    is_combined_survey
                                        ? SURVEY_TYPE_MAPPING.INDIVIDUAL_PLUS_GENERIC.label
                                        : surveydata.survey_type
                                }
                                bgColor={colorsCode.primaryCyan}
                                color={colorsCode.white}
                                onDeleteHandler={() => {}}
                                fontSize={`${pxToVh(12)}vh`}
                                lineHeight={`${pxToVh(16)}vh`}
                                fontWeight={500}
                                padding={`${pxToVh(2)}vh ${pxToVw(8)}vw`}
                                height="auto"
                                borderRadius="10px"
                            />
                        </LabelValueWrapper>
                        <LabelValueWrapper style={{ flex: 0.5 }}>
                            <CommonLabel
                                label={'Client name'}
                                lineHeight={18}
                                textSize={16}
                                textWeight={400}
                                textColor={colorsCode.Neutral30}
                            />
                            <CommonLabel
                                label={surveydata.client_name}
                                lineHeight={18}
                                textSize={16}
                                textWeight={400}
                                textColor={colorsCode.Neutral80}
                            />
                        </LabelValueWrapper>
                    </FlexRow>
                    <FlexRow style={{ width: '100%' }}>
                        <LabelValueWrapper style={{ flex: 0.5 }}>
                            <CommonLabel
                                label={'Survey status'}
                                lineHeight={18}
                                textSize={16}
                                textWeight={400}
                                textColor={colorsCode.Neutral30}
                            />
                            <CommonLabel
                                label={surveydata.status}
                                lineHeight={18}
                                textSize={16}
                                textWeight={400}
                                textColor={colorsCode.Neutral80}
                            />
                        </LabelValueWrapper>
                    </FlexRow>
                    <FormControlLabel
                        label={
                            <CommonLabel
                                label={`I have read the disclaimer and want to proceed with changing the survey status to '${changingStatusTo}'.`}
                                lineHeight={18}
                                textSize={16}
                                textWeight={400}
                                textColor={colorsCode.Neutral80}
                            />
                        }
                        control={
                            <Checkbox
                                value={checkboxSelected}
                                checked={checkboxSelected}
                                onClick={e => {
                                    changeDisclaimer(e);
                                }}
                                sx={{
                                    height: 0,
                                    width: 0,
                                    margin: `${pxToVw(6)}vw ${pxToVw(12)}vw 0 ${pxToVw(12)}vw`,
                                }}
                            />
                        }
                        sx={{ alignItems: 'self-start', marginTop: `${pxToVh(20)}vh`, fontSize: `${pxToVh(16)}vh` }}
                    />
                </FlexColumnGap>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', marginBottom: '3vh', marginTop: '3vh' }}>
                <FlexStartGap gap={pxToVw(24)}>
                    <CTA
                        pl={16}
                        pt={8}
                        lh={24}
                        size={16}
                        bg={colorsCode.white}
                        color={colorsCode.PrimaryDeepBlue}
                        bcolor={colorsCode.PrimaryDeepBlue}
                        onClick={close}
                    >
                        Cancel
                    </CTA>
                    <CTA
                        pl={16}
                        pt={8}
                        lh={24}
                        size={16}
                        bg={colorsCode.PrimaryDeepBlue}
                        color={colorsCode.white}
                        onClick={handleOnSave}
                        disabled={!checkboxSelected}
                    >
                        Save
                    </CTA>
                </FlexStartGap>
            </DialogActions>
        </Dialog>
    );
};

export const SessionModal = ({ close }) => {
    return (
        <Dialog
            open={true}
            classes={{
                root: 'warningDialog',
            }}
        >
            <h2>
                Your session has expired.
                <span data-testid="clickSpan" onClick={close}>
                    Log in
                </span>
            </h2>
        </Dialog>
    );
};

const OptionsDiv = styled.div`
    max-height: ${pxToVh(350)}vh;
    padding: ${pxToVh(20)}vh;
    border-radius: 4px;
    box-shadow: 0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.2);
    background: ${colorsCode.white};
    margin: ${pxToVh(36)}vh 0 0;
    overflow-y: auto;
`;

const PreviewDiv = styled(FlexColumnGap)`
    max-height: ${pxToVh(400)}vh;
    padding: ${pxToVh(20)}vh ${pxToVw(20)}vw;
    border-radius: 4px;
    box-shadow: 0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.2);
    background: ${colorsCode.white};
    overflow-y: auto;
    width: 100%;
`;

export const PreviewItem = styled(FlexColumnGap)`
    padding: 0 0 ${pxToVh(32)}vh 0;
    border-bottom: 1px solid ${colorsCode.Neutral10};
    width: 100%;
`;

const PreviewImage = styled.img`
    max-height: ${pxToVh(425)}vh;
    width: 100%;
    object-fit: contain;
    box-shadow: 0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.2);
`;

const QuestOptionText = styled.p`
    flex: 1;
    height: ${pxToVh(64)}vh;
    background: ${colorsCode.neutral05};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${pxToVh(12)}vh;
    line-height: ${pxToVh(16)}vh;
    color: ${colorsCode.Neutral80};
    font-weight: 300;
    text-align: center;
    padding: 0 ${pxToVw(4)}vw;
    border-radius: 4px;
    margin: 0;
`;

const StyledListContainer = styled.ul`
    margin: 0;
    padding: 0 ${pxToVh(10)}vw;
`;

const StyledList = styled.li`
    color: ${colorsCode.TextHeading};
    font-size: ${pxToVh(16)}vh;
    line-height: ${pxToVh(24)}vh;
    font-weight: 400;
`;

const WarningContainer = styled(FlexRow)`
    width: 100%;
    padding: 0 0 0 ${pxToVw(8)}vw;
`;

const NoteContainer = styled(FlexRow)`
    width: 100%;
    padding: ${pxToVw(16)}vw;
    background: ${colorsCode.pendingbg};
    border: 1px solid ${colorsCode.warning};
    border-radius: 5px;
`;

const NotesSection = styled.div`
    font-size: ${pxToVh(16)}vh;
`;
